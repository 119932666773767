import $ from 'jquery';
import { simpleEventBus } from './simpleEventBus';

class Application {
    constructor() {
        this.timeout = null;
    }

    go() {
        this.$sectors = $('.application__sector');
        this.$button = $('.application__details-container');

        this.$videoWrapper = $(document.getElementById('application__video-wrapper'));

        this.setListeners();
    }

    setListeners() {
        this.$sectors.click((e) => {
            this.$sectors.removeClass('application__sector--active');
            $(e.target).addClass('application__sector--active');
        });

        this.$button.click((e) => {
            e.stopPropagation();

            if (this.timeout) {
                return;
            }

            const { target } = e;

            let container = target;
            while (container && !container.getAttribute('data-video')) {
                container = container.parentElement;
            }

            this.$sectors.removeClass('application__sector--active');
            // application__sector component
            container.parentElement.parentElement.classList.add('application__sector--active');

            const videoName = container.getAttribute('data-video');

            if (videoName) {
                this.timeout = 'animastart';

                const t = setTimeout(() => {
                    this.play(videoName);
                    clearTimeout(t);
                }, 1000);
            }
        });
    }

    play(videoName) {
        this.$videoWrapper.addClass('play');

        let fileName = '';

        if ($('body').hasClass('lang-en')) {
            fileName = videoName.replace('.mp4', '.en.mp4');
        } else {
            fileName = videoName;
        }

        const pt = setTimeout(() => {
            this.$videoWrapper.html(`
                <video autoplay muted id="application__video">
                    <source src="./videos/${fileName}" type="video/mp4">
                </video>
                <div class="video__close"></div>
                <div class="sound sound--on"></div>
            `);

            const videoEl = document.getElementById('application__video');
            const $sound = $('.sound');
            videoEl.onended = () => this.end();
            this.$videoWrapper.focus();

            this.hideStuff();

            simpleEventBus.emit('video.start');

            setTimeout(() => {
                $('.video__close').click((e) => {
                    this.showStuff();
                    this.end();
                });
                $sound.click(() => {
                    if ($sound.hasClass('sound--on')) {
                        videoEl.muted = false;
                        $sound.removeClass('sound--on');
                    } else {
                        videoEl.muted = true;
                        $sound.addClass('sound--on');
                    }
                });
            }, 100);
            clearTimeout(pt);
        }, 700);
    }

    end() {
        this.$videoWrapper.removeClass('play');
        this.$videoWrapper.html('');
        clearTimeout(this.timeout);
        this.timeout = null;

        simpleEventBus.emit('video.end');

        const t = setTimeout(() => {
            this.showStuff();
            clearTimeout(t);
        }, 0);
    }

    showStuff() {
        $('.sound').removeClass('sound--fullscreen');
        $('.video__close').removeClass('video__close--fullscreen');
    }

    hideStuff() {
        $('.sound').addClass('sound--fullscreen');
        $('.video__close').addClass('video__close--fullscreen');
    }
}

export function m_application() {
    const a = new Application();
    a.go();
}
