import $ from 'jquery';
import Swiper from "swiper";

export function m_connection() {
    const section = $('.section.connection');

    const indexMap = {
        node: 0,
        edge: 1,
        loop: 2,
    };

    const s = new Swiper($('.swiper-container'), {
        direction: 'horizontal',
        pagination: {
            el: '.swiper-pagination',
            clickable: false,
        },
        simulateTouch: true,
    });

    const updateAciveLens = (mode) => {
        const frame = $('.connection__lens-frame');

        section.removeClass('active--node active--edge active--loop');
        section.addClass(`active--${mode}`);

        frame.removeClass('frame--node frame--edge frame--loop');
        frame.addClass(`frame--${mode}`);
    };

    $('.connectionInteractive, .connection__pulse').on('click mouseover touch', (e) => {
        const t = setTimeout(() => {
            const m = $(e.target).attr('data-mode');
            s.slideTo(indexMap[m] || 0);
            clearTimeout(t);
        }, 24);
    });

    $('.connectionInteractive, .connection__pulse').one('click mouseover touch', (e) => {
        const mode = $(e.target).attr('data-mode');
        const frame = $('.connection__lens-frame');
        const lens = $('.connection__pipe_img-lens');

        frame.addClass(`frame-inited--${mode}`);
        lens.addClass(`lens-inited--${mode}`);
    });

    s.on('slideChange', function () {
        const t = setTimeout(() => {
            const m = $('.swiper-slide-active')
                .children(':first-child')
                .attr('data-mode');

            updateAciveLens(m);

            const frame = $('.connection__lens-frame');
            const lens = $('.connection__pipe_img-lens');

            frame.addClass(`frame-inited--${m}`);
            lens.addClass(`lens-inited--${m}`);

            clearTimeout(t);
        }, 24);
    });
}
