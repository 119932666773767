import $ from 'jquery';
import { debounce } from "./debounce";

const OFFSET = 10;

export function m_navbar() {
    let height;

    // $(window).scroll(debounce(function () {
    //     if (!height) {
    //         height = $('.navbar').height();
    //     }
    //
    //     if ($(this).scrollTop() >= height + OFFSET) {
    //         $('.navbar').addClass('navbar--sticky');
    //     } else {
    //         $('.navbar').removeClass('navbar--sticky');
    //     }
    // }, 50));
}
