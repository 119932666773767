import $ from 'jquery';

export function m_exploitation() {
    setActive("1");
    $('.exploitation__icon').click((e) => {
        const {currentTarget} = e;
        const number = currentTarget.getAttribute('data-section-number');
        setActive(number);
    });
}

function setActive(sectionNumber) {
    $('.exploitation__section').removeClass('active');
    $('.exploitation__icon').removeClass('active');

    const section = $('.section.exploitation');
    [1, 2, 3].forEach(i => section.removeClass(`bg--${i}`));

    $(`.exploitation__section--${sectionNumber}`).addClass('active');
    $(`.exploitation__icon--${sectionNumber}`).addClass('active');
    section.addClass(`bg--${sectionNumber}`);
}
