import $ from 'jquery';
import { throttle } from "./throttle";

export function mbg() {
    const $window = $(window);

    $window.on('resize', throttle(() => {

    }, 100));
}

function setSize() {
    const $window = $(window);
    $('.m-bg').attr('style', `height: ${$window.height()}px`);
}
