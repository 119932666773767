import $ from 'jquery';
import { Loader } from './loader';
import './contacts';
import { language } from "./language";
import home from "./home";
import { m_exploitation } from "./m_exploitation";
import { m_application } from "./m_application";
import { m_navbar } from "./m_navbar";
import m_menu from "./m_menu";
import { m_connection } from "./m_connection";
import { mbg } from "./mbg";

$(document)
    .ready(() => {
        const loader = new Loader();

        $('.afterLoader').hide();

        loader.onFinish(() => {
            $('.afterLoader').show();

            m_navbar();
            m_menu();

            home();
            language();
            m_connection();
            m_exploitation();
            m_application();
            mbg();
        });

        loader.go();
    });
