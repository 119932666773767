import $ from 'jquery';

export const m_menu = () => {
    const openMenu = () => {
        const m = $('.menu');
        const s = $('.section');
        const h = $('html, body');
        const n = $('.navbar');

        m.scrollTop(0);
        s.addClass('section--hide');
        m.addClass('menu--show');
        h.css('overflow', 'hidden');
        // n.removeClass('navbar--sticky');
        $('.navbar__menu__main').addClass('menu__full-active');
        setTimeout(() => {
            if (m.hasClass('menu--show')) {
                n.css('backgroundColor', '#222121');
            }
        }, 1200);
    };

    const closeMenu = () => {
        const m = $('.menu');
        const s = $('.section');
        const h = $('html, body');
        const n = $('.navbar');

        s.removeClass('section--hide');
        m.removeClass('menu--show');
        h.css('overflow', 'initial');
        n.css('backgroundColor', '');
        $('.navbar__menu__main').removeClass('menu__full-active');
        // if ($(window).scrollTop() >= n.height() + 10) {
        //     n.addClass('navbar--sticky');
        // }
    };

    let lastId;
    const menuItems = $('.menu__list > li > a');
    const scrollItems = menuItems.map(function () {
        const item = $($(this).attr('href'));
        if (item.length) {
            return item;
        }
    });

    menuItems.click(function (e) {
        const href = $(this).attr('href');
        const offsetTop = $(href).offset().top + 1;

        $('html, body').stop().animate({
            scrollTop: offsetTop,
        }, 600);
        e.preventDefault();

        closeMenu();
    });

    $(window).scroll(function () {
        const fromTop = $(this).scrollTop();

        let cur = scrollItems.map(function () {
            if ($(this).offset().top <= fromTop) {
                return this;
            }
        });

        cur = cur[cur.length - 1];
        const id = cur && cur.length ? cur[0].id : '';
        if (lastId !== id) {
            lastId = id;

            menuItems
                .parent().removeClass('menu__list__item-active')
                .end()
                .filter(`[href='#${id}']`)
                .parent()
                .addClass('menu__list__item-active');
        }
    });

    $('.navbar__menu__main')
        .click(() => {
            const opened = $('.menu').hasClass('menu--show');

            if (opened) {
                closeMenu();
            } else {
                openMenu();
            }
        });
};

export default m_menu;
